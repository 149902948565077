// import Component from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import { Aggregate, AggSearchCriteria, DataServiceAggregateQuery, GeneralSearchCriteria, ReportData, ShortRecord, SummaryTotalsMeta } from '@/store/models';
import { EmptyAggSearchCriteria, EmptyGeneralSearchCriteria } from '@/store/models-empty';
import AggregateHelper from './AggregateHelper';
import { aggregatesToShortRecords, getSearchCriteriaIdentifier, handleError } from "../lib/shared";
import ReportHelper, { ReportType } from './ReportHelper';
import BaseComponent from './BaseComponent';
import { saveAs } from "file-saver";
import * as datetime from "../lib/datetime";
import * as _ from "lodash";

// High Charts
import { Chart } from "highcharts-vue";
import Highcharts, { chart } from "highcharts";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import loadExporting from "highcharts/modules/exporting";
import loadExportData from "highcharts/modules/export-data";
import loadDrilldown from "highcharts/modules/drilldown";
import loadDataTableToggle from "../lib/highcharts-datatable.js";
import loadTreemap from "highcharts/modules/treemap";
import * as charts from "../lib/charts";
import { createReportPdf, getExpenseAggregates, getExpenseLocations, getShortList, searchAggregatesByRelation } from '@/store/rest.service';
import { base64Encode } from '@/lib/reports';

loadDrilldown(Highcharts);
loadExporting(Highcharts);
loadExportData(Highcharts);
HighchartsNoData(Highcharts);
loadDataTableToggle(Highcharts);
loadTreemap(Highcharts);

@Component({})
export default class BaseReport extends BaseComponent {
    protected baseRoute: string = "/reports";

    protected ReportType = ReportType;
    protected reportType: string = '';

    protected arrErrors: Error[] = [];

    protected currentOrgHelper: AggregateHelper = new AggregateHelper();
    protected currentOrgHelperLoading: boolean = true;
    protected currentOrgHelperUpdated: number = 0;

    protected reportHelper: ReportHelper = new ReportHelper(ReportType.Unknown);
    protected reportHelperLoading: boolean = true;
    protected reportHelperUpdated: number = 0;

    protected searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);

    protected generateReport: boolean = false;
    protected resizeCharts: number = 0;
    protected updateDates: number = 0;
    protected isFullScreen: boolean = false;
    protected updateArgs: any = charts.getUpdateArgs();
    protected maxChartItems: number = 1000;
    protected pdfLoading: boolean = false;
    protected bInitialLoad: boolean = true;
    protected bNoProjectSelected = false;
    protected bNoClientSelected = false;
    protected bNoCommunitySelected = false;
    protected bNoLocationSelected = false;

    // Filters.
    protected ics: ShortRecord[] | null = null;
    protected icsLoading: boolean = false;

    protected orgIcs: ShortRecord[]|null = null;
    protected orgIcsLoading: boolean = false;

    protected projects: ShortRecord[] | null = null;
    protected projectsLoading: boolean = false;

    protected locations: ShortRecord[] | null = null;
    protected locationsLoading: boolean = false;

    protected clients: ShortRecord[] | null = null;
    protected clientsLoading: boolean = false;

    protected project: string = '';
    protected community: string = '';
    protected client: string = '';
    protected location = "";

    protected mounted() {
        this.mount();
    }

    // Override in derived class to get the data for the report.
    protected getReportData(): ReportData|null  {
        return null;
    }

    // Override in derived class to set the data for the report.
    protected setCharts() { }

    protected changeCurrentOrg() {
        this.mount();
        this.refreshCharts();
    }

    protected beforeDestroy() {
        this.$el.removeEventListener("fullscreenchange", this.fullScreenChanged);
    }

    protected changeProject() {
        this.bNoProjectSelected = false;
        this.bInitialLoad = true;
        this.refreshCharts();
    }

    protected changeCommunity() {
        this.bNoCommunitySelected = false;
        this.bInitialLoad = true;
        this.refreshCharts();
    }

    protected changeClient() {
        this.bNoClientSelected = false;
        this.bInitialLoad = true;
        this.refreshCharts();
    }

    protected changeLocation() {
        this.bNoLocationSelected = false;
        this.bInitialLoad = true;
        this.refreshCharts();
    }

    private mount() {
        this.$el.addEventListener("fullscreenchange", this.fullScreenChanged);
        this.reportType = this.$route.params.reportType;
        this.reportHelper.reportType = this.reportHelper.reportTypeStringToEnum(this.reportType);
        this.setSearchCriteria();
        this.setSearchFilters();
        if (!this.isSearchFiltersLoading()) {
            this.refreshCharts();
        }
        this.baseRoute = "/reports/" + this.reportType;
    }

    @Watch("currentOrgHelper.isLoading")
    private onCurrentOrgHelperChanged(val: any, oldVal: any) {
        this.currentOrgHelperLoading = this.currentOrgHelper.isLoading;
        if (this.bInitialLoad && !this.currentOrgHelperLoading) {
            // Set the default dates using the Project start date.
            this.setSearchCriteria();
            this.bInitialLoad = false;
            // Refresh dara only if report is project type
            if (this.reportHelper.isProjectTypeReport()) {
                this.refreshCharts();
            }
        } else {
            this.currentOrgHelperUpdated++;
            this.resize();
        }
    }

    @Watch("reportHelper.isLoading")
    private onReportHelperChanged(val: any, oldVal: any) {
        this.reportHelperLoading = this.reportHelper.isLoading;
        if (!this.currentOrgHelperLoading) {
            this.bInitialLoad = false;
        }
        this.reportHelperUpdated++;
    }

    @Watch("reportHelper.arrErrors")
    private onReportHelperError(val: any, oldVal: any) {
        this.arrErrors = val;
    }

    @Watch("currentOrgHelper.arrErrors")
    private onCurrentOrgHelperError(val: any, oldVal: any) {
        this.arrErrors = val;
    }

    private setSearchFilters() {
        this.project = this.$route.query['project'] ? this.$route.query['project'].toString() : '';
        if (this.project !== '') {
            this.getProjects();
        }
        this.community = this.$route.query['community'] ? this.$route.query['community'].toString() : '';
        if (this.community !== '') {
            this.getOrgCommunities();
        }
        this.client = this.$route.query['client'] ? this.$route.query['client'].toString() : '';
        if (this.client !== '') {
            this.getClients();
        }
        this.location = this.$route.query['location'] ? this.$route.query['location'].toString() : '';
        if (this.location !== '') {
            this.getLocations();
        }
    }

    private setSearchCriteria() {
        if (this.reportHelper.isCCABTypeReport()) {
            this.searchCriteria.dateFrom = `${new Date().getFullYear() - 3}-01-01`;
            this.searchCriteria.dateTo = `${new Date().getFullYear() - 1}-12-31`;
        } else {
            if (this.reportHelper.isProjectTypeReport()) {
                this.searchCriteria.dateFrom = this.currentOrgHelper.project.project.startDate;
            }
            if (this.reportHelper.isVisible([ReportType.SocialImpactLocation])) {
                this.searchCriteria.dateFrom = `${new Date().getFullYear()}-01-01`;
            }
            this.searchCriteria.dateTo = datetime.getCurrentDate();
        }
        // this.searchCriteria.clients = this.currentOrgHelper.project.project.creatorVendor as ShortRecord;
        this.updateDates++;
    }

    private isLoading(): boolean {
        return (this.currentOrgHelperLoading || this.reportHelperLoading) && !this.bNoProjectSelected && !this.bNoCommunitySelected && !this.bNoClientSelected && !this.bNoLocationSelected;
    }

    private isSearchFiltersLoading(): boolean {
        return this.icsLoading || this.projectsLoading || this.clientsLoading || this.locationsLoading;
    }

    private getAggSearchCriteria(): AggSearchCriteria {
        const aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
        aggSearchCriteria.hasPayer = this.currentOrg;
        const selectedCommunity: string|null = this.reportHelper.getCommunity(this.searchCriteria);
        if (selectedCommunity) {
            aggSearchCriteria.hasBeneficiary = selectedCommunity;
        }
        const selectedClient: string|null = this.reportHelper.getClient(this.searchCriteria);
        if (selectedClient) {
            aggSearchCriteria.hasPayee = selectedClient;
        }
        const selectedProject: string|null = this.reportHelper.getProject(this.searchCriteria);
        if (selectedProject) {
            aggSearchCriteria.hasProject = selectedProject;
        }
        return aggSearchCriteria;
    }

    private refreshCharts() {
        this.currentOrg =
            this.$store.getters["local/CURRENT_ORG"] !== ""
            ? this.$store.getters["local/CURRENT_ORG"]
            : "";
        if (this.reportHelper.isProjectTypeReport()) {
            const projectId: string = getSearchCriteriaIdentifier(this.searchCriteria.projects);
            if (projectId !== '') {
                if (this.bInitialLoad) {
                    // Get the Project first because we need the Project Start and End dates.
                    this.currentOrgHelper = new AggregateHelper();
                    this.currentOrgHelper.getProject(projectId);
                } else {
                    this.currentOrgHelper.populateProjectDataLists(projectId, this.searchCriteria, this.getAggSearchCriteria(), false);
                    this.reportHelper.populateDataLists(this.currentOrg, this.searchCriteria);
                }
            } else {
                // Stop loading until a project is selected.
                this.bNoProjectSelected = true;
            }
        } else {
            if (this.reportHelper.isCommunityTypeReport()) {
                if (this.reportHelper.getCommunity(this.searchCriteria) == null) {
                    this.bNoCommunitySelected = true;
                }
            }
            if (this.reportHelper.isClientTypeReport()) {
                if (this.reportHelper.getClient(this.searchCriteria) == null) {
                    this.bNoClientSelected = true;
                }
            }
            if (this.reportHelper.isLocationTypeReport()) {
                if (this.reportHelper.getLocation(this.searchCriteria) == null) {
                    this.bNoLocationSelected = true;
                }
            }
            if (!(this.bNoClientSelected || this.bNoCommunitySelected || this.bNoLocationSelected)) {
                this.currentOrgHelper = new AggregateHelper();
                if (this.reportHelper.isCCABTypeReport()) {
                    const currentYear = new Date().getFullYear();
                    const years = [currentYear - 1, currentYear - 2, currentYear - 3];
                    this.currentOrgHelper.populateVendorDataLists(
                        this.currentOrg,
                        this.searchCriteria,
                        this.getAggSearchCriteria(),
                        false,
                        years,
                    );
                } else {
                    this.currentOrgHelper.populateVendorDataLists(
                        this.currentOrg,
                        this.searchCriteria,
                        this.getAggSearchCriteria(),
                        false,
                    );
                }
                this.reportHelper.populateDataLists(this.currentOrg, this.searchCriteria);
            }
        }
    }

    private cancelPDF() {
        this.generateReport = false;
    }

    private exportToPDF(bOpen: boolean = false, bPehtaSecure: boolean = false) {
        // Ensure any deleted properties are present in the search criteria.
        const emptySearchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
        Object.keys(emptySearchCriteria).forEach((key) => this.searchCriteria[key] ? null : this.searchCriteria[key] = emptySearchCriteria[key]);

        const fileName = this.reportHelper.getFilename(this.reportHelper.reportType, this.searchCriteria, this.getCurrentOrgName(this.currentOrg), bPehtaSecure);
        const reportData: ReportData|null = this.getReportData();
        this.generateReport = true;

        if (reportData) {
            reportData.userInfo = this.$store.getters['session/CURRENT_USER'];
        }

        createReportPdf(reportData).then((responseData) => {
            if (this.generateReport) {
                if (bOpen) {
                    const blob = new Blob([responseData], {type: "application/pdf"});
                    const url = URL.createObjectURL(blob);
                    window.open(url, "_blank");
                } else {
                    const blob = new Blob([responseData], {type: "application/octet-stream"});
                    saveAs(blob, fileName);
                }

                this.generateReport = false;
            }
        });
    }

    private pdfFormSubmit(data: any, fileName: string) {
        const encodedReportData: string = base64Encode(JSON.stringify(data));
        const reportingServicebaseUrl: string = process.env.VUE_APP_RS_URL;
        //const reportingServicebaseUrl: string = "http://localhost:7071/api";
        const form = document.createElement("form");
        const timestamp: string = (+ new Date()).toString();
        const formId: string = "pdfForm" + timestamp;
        form.setAttribute("id", formId);
        document.body.append(form);
        const addedForm = document.getElementById(formId);
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = "";
        hiddenInput.value = encodedReportData;
        if (addedForm) {
            addedForm.appendChild(hiddenInput);
            form.setAttribute("method", "post");
            form.setAttribute("enctype", "application/x-www-form-urlencoded");
            // form.setAttribute("action", reportingServicebaseUrl + "/report/generate/" + fileName);
            // form.setAttribute("action", reportingServicebaseUrl + "/report/generate" + "?t=" + timestamp);
            form.setAttribute("action", reportingServicebaseUrl + "/report/generate");
            //form.setAttribute("target", "_blank");
            form.submit();
            document.body.removeChild(form);
        }
    }

    private getProjects() {
        if (!this.projects) {
            this.projectsLoading = true;
            const whereClauses: Array<[string, string[]]> = [];
            getShortList("Project", false, whereClauses, false, "poId")
            .then((response) => {
                this.projects = response.searchResults.results as ShortRecord[];
                // Auto-select the project in the list if it is passed in on the qs.
                if (this.project !== '') {
                const selectedProject: ShortRecord|undefined = this.projects.find((project) => project.identifier === this.project);
                if (selectedProject) {
                    this.searchCriteria.projects = selectedProject;
                }
                this.refreshCharts();
                }
            })
            .catch((error) => {
                handleError(this.arrErrors, error);
            })
            .finally(() => {
                this.projectsLoading = false;
            });
        }
    }

    private getOrgCommunities() {
        if (!this.orgIcs) {
            this.orgIcsLoading = true;
            const searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
            searchAggregatesByRelation(searchCriteria, 'relatedic', this.currentOrg)
            .then((response) => {
                this.orgIcs = aggregatesToShortRecords(this.filterCommunities(response.searchResults.results as Aggregate[]));
                // Auto-select the community in the list if it is passed in on the qs.
                if (this.community !== '') {
                    const selectedCommunity: ShortRecord|undefined = this.orgIcs.find((community) => community.identifier === this.community);
                    if (selectedCommunity) {
                        this.searchCriteria.organizations = selectedCommunity;
                    }
                    this.changeCommunity();
                }
            }).catch((error) => {
            this.arrErrors.push(error);
            }).finally(() => {
            this.orgIcsLoading = false;
            });
        }
    }

    private getProjectCommunities() {
        if (!this.orgIcs) {
            this.orgIcsLoading = true;
            const expenseQuery: DataServiceAggregateQuery = {
                groupBy: "payment.hasBeneficiary",
                dateFrom: this.searchCriteria.dateFrom,
                dateTo: this.searchCriteria.dateTo,
                constraints: {},
            };
            if (this.searchCriteria.projects)  {
                expenseQuery.constraints["payment.hasProject"] = (this.searchCriteria.projects as ShortRecord).identifier as string;
            }

            getExpenseAggregates(expenseQuery, this.currentOrg).then((response) => {
                const communities: ShortRecord[] = [];
                for (const item of response) {
                    if (item.identifier && item.identifier !== "urn:nisto-link:id:non-indigenous:no-999999") {
                        communities.push({
                            displayName: item.displayName,
                            identifier: item.identifier,
                        });
                    }
                }
                this.orgIcs = communities;
            }).catch((error) => {
                this.arrErrors.push(error);
            }).finally(() => {
                this.orgIcsLoading = false;
            });
        }
    }

    private getLocations() {
        if (!this.locations) {
            this.locationsLoading = true;
            getExpenseLocations().then((response) => {
                this.locations = response.map((item) => {
                    return {
                        identifier: item.value,
                        displayName: item.value,
                    };
                });

                // Auto-select the location in the list if it is passed in on the qs.
                if (this.location !== '') {
                    const selectedLocation: ShortRecord|undefined = this.locations.find((location) => location.identifier === this.location);
                    if (selectedLocation) {
                        this.searchCriteria.locations = selectedLocation;
                    }
                    this.refreshCharts();
                }
            })
            .catch((error) => {
                handleError(this.arrErrors, error);
            })
            .finally(() => {
                this.locationsLoading = false;
            });
        }
    }

    private filterCommunities(records: Aggregate[]): Aggregate[] {
        switch (this.reportHelper.reportType) {
            case ReportType.TangibleNetBenefitsCommunity:
                return records.filter((i) => this.totalsHasBenefits((i.totals as SummaryTotalsMeta)));
            case ReportType.SocialProcurementCommunity:
                return records.filter((i) => this.totalsHasDIS((i.totals as SummaryTotalsMeta)));
            case ReportType.IndigenousImpactCommunity:
                return records.filter((i) => (this.totalsHasBenefits((i.totals as SummaryTotalsMeta)) || this.totalsHasDIS((i.totals as SummaryTotalsMeta))));
            default:
                return records;
        }
    }

    private totalsHasBenefits(totals: SummaryTotalsMeta): boolean {
        return (
            (totals.expenses.employment ? totals.expenses.employment.totalIndigenousEmploymentValue > 0 : false)
            || totals.expenses.expenseTotals.totalIndigenousProjectContributions > 0
            || totals.expenses.expenseTotals.totalIndigenousCommunityContributions > 0
           );
    }

    private totalsHasDIS(totals: SummaryTotalsMeta): boolean {
        return (
           totals.expenses.diverseSpend ? totals.expenses.diverseSpend.totalDirectIndigenousSpend > 0 : false
           );
    }

    private getIndigenousCommunities() {
        if (!this.ics) {
            this.icsLoading = true;
            getShortList("IndigenousCommunity", false, [], true)
            .then((response) => {
                this.ics = response.searchResults.results as ShortRecord[];
                // Auto-select the community in the list if it is passed in on the qs.
                if (this.community !== '') {
                    const selectedCommunity: ShortRecord|undefined = this.ics.find((community) => community.identifier === this.community);
                    if (selectedCommunity) {
                        this.searchCriteria.organizations = selectedCommunity;
                    }
                    this.refreshCharts();
                }
            })
            .catch((error) => {
                handleError(this.arrErrors, error);
            })
            .finally(() => {
                this.icsLoading = false;
            });
        }
    }

    private getClients() {
        /*
        if (!this.clients) {
          this.clientsLoading = true;
          const emptySearchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
          searchAggregatesByRelation(emptySearchCriteria, 'client', this.currentOrg)
            .then((response) => {
              this.clients = aggregatesToShortRecords(response.searchResults.results as Aggregate[]);
              // Auto-select the client in the list if it is passed in on the qs.
              if (this.client !== '') {
                const selectedClient: ShortRecord|undefined = this.clients.find((client) => client.identifier === this.client);
                if (selectedClient) {
                  this.searchCriteria.clients = selectedClient;
                }
                this.refreshCharts();
              }
            })
            .catch((error) => {
              handleError(this.arrErrors, error);
            })
            .finally(() => {
              this.clientsLoading = false;
            });
        }
        */
    }

    private fullScreenChanged() {
        this.isFullScreen = !this.isFullScreen;
        if (!this.isFullScreen) {
            this.resizeCharts++;
        }
    }

    private resize() {
        this.resizeCharts++;
        charts.resize();
    }
}
