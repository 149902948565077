import { searchAggregatesById, searchAggregateSeries, getProject, getVendor } from '../store/rest.service';
import { EmptyGeneralSearchCriteria, EmptyTotalsAggregate, EmptyVendor, EmptyProject, EmptyImpactTargetsMeta, EmptyAggSearchCriteria } from '../store/models-empty';
import { ResourceSearchResult, GeneralSearchCriteria, Aggregate, TotalsAggregate, OccupationAggregate, AggregateTotalsMeta, Vendor, Project, ExpenseTotalsMeta, ImpactTargetsMeta, AggSearchCriteria } from '../store/models';
import { handleError } from '../lib/shared';
import { convertAggregateToTotalsAggregate /*, convertAggregateToOccupationAggregate */  } from '../lib/aggregate';
import * as datetime from '../lib/datetime';
import * as _ from 'lodash';

export default class AggregateHelper {

  public arrErrors: Error[] = [];
  public isLoading: boolean = true;
  public searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  public aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
  public searchCriteriaPreviousYear: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  public searchCriteriaPreviousYearToDate: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  public totalsAggregate: TotalsAggregate = _.cloneDeep(EmptyTotalsAggregate);
  public totalsAggregatePreviousYear: TotalsAggregate = _.cloneDeep(EmptyTotalsAggregate);
  public totalsAggregatePreviousYearToDate: TotalsAggregate = _.cloneDeep(EmptyTotalsAggregate);
  public totalsAggregateManyYears: { [key: number]: TotalsAggregate; } = {};
  public employmentByOccupation: OccupationAggregate[] = [];
  public id: string = '';

  public aggregate: any = {};
  public aggregateLoading: boolean = false;

  public aggregatePreviousYear: any = {};
  public aggregatePreviousYearLoading: boolean = false;

  public aggregatePreviousYearToDate: any = {};
  public aggregatePreviousYearToDateLoading: boolean = false;

  public totalsAggregateManyYearsLoading: boolean = false;

  public aggregateSeries: number[] = [];
  public aggregateSeriesLabels: string[] = [];
  public aggregateSeriesFirstDate: string = '';
  public aggregateSeriesLastDate: string = '';
  public aggregateSeriesLoading: boolean = false;

  public aggregateSeriesPreviousYear: number[] = [];
  public aggregateSeriesPreviousYearFirstDate: string = '';
  public aggregateSeriesPreviousYearLastDate: string = '';
  public aggregateSeriesPreviousYearLoading: boolean = false;

  public compareToPreviousYear: boolean = false;
  public impactTargets: ImpactTargetsMeta = EmptyImpactTargetsMeta;
  public project: Project =  _.cloneDeep(EmptyProject);
  public vendor: Vendor = _.cloneDeep(EmptyVendor);
  private vendorLoading: boolean = false;
  private projectLoading: boolean = false;

  public setIsLoading(): void {
    this.isLoading = (
      this.aggregateLoading
      || this.aggregatePreviousYearLoading
      || this.aggregatePreviousYearToDateLoading
      || this.aggregateSeriesLoading
      || this.aggregateSeriesPreviousYearLoading
      || this.totalsAggregateManyYearsLoading
      || this.vendorLoading
      || this.projectLoading
    );
  }

  public populateVendorDataLists(id: string, searchCriteria: GeneralSearchCriteria|null = null, aggSearchCriteria: AggSearchCriteria|null = null, compareToPreviousYear: boolean = false, aggSearchYears: number[] = []) {
    this.getVendor(id);
    this.populateDataLists(id, searchCriteria, aggSearchCriteria, compareToPreviousYear, aggSearchYears);
  }

  public populateProjectDataLists(id: string, searchCriteria: GeneralSearchCriteria|null = null, aggSearchCriteria: AggSearchCriteria|null = null, compareToPreviousYear: boolean = false) {
    this.populateDataLists(id, searchCriteria, aggSearchCriteria, compareToPreviousYear);
  }

  public populateDataLists(id: string, searchCriteria: GeneralSearchCriteria|null = null, aggSearchCriteria: AggSearchCriteria|null = null, compareToPreviousYear: boolean = false, aggSearchYears: number[] = []) {
    this.id = id;
    this.compareToPreviousYear = compareToPreviousYear;
    if (searchCriteria) {
      this.searchCriteria = searchCriteria;
    }
    if (aggSearchCriteria) {
      this.aggSearchCriteria = aggSearchCriteria;
    }
    this.searchAggregate();

    // Perform aggregate search on multiple years if provided
    if (aggSearchYears.length > 0) {
      this.searchAggregateMultipleYears(aggSearchYears);
    }

    if (compareToPreviousYear) {
      // Set the search criteria for the previous year.
      this.searchCriteriaPreviousYear = _.cloneDeep(this.searchCriteria);
      this.searchCriteriaPreviousYear.dateFrom = datetime.getLastYearDateStart(this.searchCriteria.dateFrom);
      this.searchCriteriaPreviousYear.dateTo = datetime.getLastYearDateEnd(this.searchCriteria.dateTo);
      // Set the search criteria for the previous year to date.
      this.searchCriteriaPreviousYearToDate = _.cloneDeep(this.searchCriteria);
      this.searchCriteriaPreviousYearToDate.dateFrom = datetime.getLastYearDateStart(this.searchCriteria.dateFrom);
      this.searchCriteriaPreviousYearToDate.dateTo = datetime.getLastYearDate(this.searchCriteria.dateTo);
      this.searchPreviousYearAggregate();
      this.searchPreviousYearToDateAggregate();
      this.searchAggregateSeries();
      this.searchAggregateSeriesPreviousYear();
    }
  }

  public searchAggregateSeries(): any {
    this.aggregateSeriesLoading = true;
    this.setIsLoading();
    searchAggregateSeries(this.searchCriteria, 'tnb', 'project')
    .then((response) => {
      let index: number = 0;
      for (const totalTnb of (response.searchResults.cumulativeTotals as AggregateTotalsMeta).totalTnb) {
        this.aggregateSeries.push(totalTnb);
        index++;
      }
      this.aggregateSeriesLabels = response.searchResults.labels as string[];
      this.aggregateSeriesFirstDate = (response.searchResults.cumulativeTotals as AggregateTotalsMeta).firstDate;
      this.aggregateSeriesLastDate = (response.searchResults.cumulativeTotals as AggregateTotalsMeta).lastDate;
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.aggregateSeriesLoading = false;
      this.setIsLoading();
      });
  }

  public searchAggregateSeriesPreviousYear(): any {
    this.aggregateSeriesPreviousYearLoading = true;
    this.setIsLoading();
    searchAggregateSeries(this.searchCriteriaPreviousYear, 'tnb', 'project')
    .then((response) => {
      let index: number = 0;
      for (const totalTnb of (response.searchResults.cumulativeTotals as AggregateTotalsMeta).totalTnb) {
        this.aggregateSeriesPreviousYear.push(totalTnb);
        index++;
      }
      this.aggregateSeriesPreviousYearFirstDate = (response.searchResults.cumulativeTotals as AggregateTotalsMeta).firstDate;
      this.aggregateSeriesPreviousYearLastDate = (response.searchResults.cumulativeTotals as AggregateTotalsMeta).lastDate;
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.aggregateSeriesPreviousYearLoading = false;
      this.setIsLoading();
      });
  }

  public searchAggregate(): any {
    this.aggregateLoading = true;
    this.setIsLoading();
    searchAggregatesById(this.searchCriteria, this.aggSearchCriteria, this.id)
    .then((response) => {
      this.aggregate = response;
      const aggregate = ((this.aggregate as ResourceSearchResult).searchResults.results[0] as Aggregate);
      this.totalsAggregate = convertAggregateToTotalsAggregate(aggregate);
      // this.employmentByOccupation = convertAggregateToOccupationAggregate(aggregate);
    }).catch((error) => {
      handleError(this.arrErrors, error);
     }).finally(() => {
      this.aggregateLoading = false;
      this.setIsLoading();
    });
  }

  public searchPreviousYearAggregate(): any {
    this.aggregatePreviousYearLoading = true;
    this.setIsLoading();
    searchAggregatesById(this.searchCriteriaPreviousYear, this.aggSearchCriteria, this.id)
    .then((response) => {
      this.aggregatePreviousYear = response;
      const aggregate = ((this.aggregatePreviousYear as ResourceSearchResult).searchResults.results[0] as Aggregate);
      this.totalsAggregatePreviousYear = convertAggregateToTotalsAggregate(aggregate);
    }).catch((error) => {
      handleError(this.arrErrors, error);
     }).finally(() => {
      this.aggregatePreviousYearLoading = false;
      this.setIsLoading();
    });
  }

  public searchPreviousYearToDateAggregate(): any {
    this.aggregatePreviousYearToDateLoading = true;
    this.setIsLoading();
    searchAggregatesById(this.searchCriteriaPreviousYearToDate, this.aggSearchCriteria, this.id)
    .then((response) => {
      this.aggregatePreviousYearToDate = response;
      const aggregate = ((this.aggregatePreviousYearToDate as ResourceSearchResult).searchResults.results[0] as Aggregate);
      this.totalsAggregatePreviousYearToDate = convertAggregateToTotalsAggregate(aggregate);
    }).catch((error) => {
      handleError(this.arrErrors, error);
     }).finally(() => {
      this.aggregatePreviousYearToDateLoading = false;
      this.setIsLoading();
    });
  }

  public searchAggregateMultipleYears(years: number[]): void {
    this.totalsAggregateManyYearsLoading = true;
    this.setIsLoading();

    // Create request for each aggregate year
    const searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    const aggregateRequests: Array<Promise<ResourceSearchResult>> = [];
    years.forEach((year) => {
      searchCriteria.dateFrom = `${year}-01-01`;
      searchCriteria.dateTo = `${year}-12-31`;
      aggregateRequests.push(searchAggregatesById(searchCriteria, this.aggSearchCriteria, this.id));
    });

    // Wait for all requests to complete
    Promise.all(aggregateRequests).then((aggregateResponses) => {
      aggregateResponses.forEach((response, index) => {
        const year = years[index];
        const aggregate = ((response as ResourceSearchResult).searchResults.results[0] as Aggregate);
        this.totalsAggregateManyYears[year] = convertAggregateToTotalsAggregate(aggregate);
      });
    }).catch((error) => {
      handleError(this.arrErrors, error);
     }).finally(() => {
      this.totalsAggregateManyYearsLoading = false;
      this.setIsLoading();
    });
  }

  public getProject(id: string) {
    this.projectLoading = true;
    this.setIsLoading();
    getProject(id, true).then((response) => {
      this.project = response;
      this.impactTargets = this.project.project.impactTargets ? this.project.project.impactTargets : _.cloneDeep(EmptyImpactTargetsMeta);
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.projectLoading = false;
      this.setIsLoading();
    });
  }

  public getVendor(id: string) {
    this.vendorLoading = true;
    this.setIsLoading();
    getVendor(id).then((response) => {
      this.vendor = response;
      if (!this.vendor.vendor.impactTargets.indigenousProjectBenefit) {
        this.vendor.vendor.impactTargets.indigenousProjectBenefit = 0;
      }
      this.impactTargets = this.vendor.vendor.impactTargets;
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.vendorLoading = false;
      this.setIsLoading();
    });
  }

  public handleError(error: any) {
    handleError(this.arrErrors, error);
  }
}
