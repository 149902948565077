


























































































































































































































































































































































































































































































































































import { Component } from "vue-property-decorator";
import BaseReport from "../components/BaseReport";
import SpinnerComponent from "../components/SpinnerComponent.vue";
import DialogComponent from "../components/DialogComponent.vue";
import ErrorComponent from "../components/ErrorComponent.vue";
import DatePickerComponent from "../components/DatePickerComponent.vue";
import SocialProcurementComponent from "../components/SocialProcurementComponent.vue";
import SocialProcurementReportComponent from "../components/SocialProcurementReportComponent.vue";
import WorkforceDiversityComponent from "../components/WorkforceDiversityComponent.vue";
import DiversityBreakdownComponent from "../components/DiversityBreakdownComponent.vue";
import IndigenousBenefitsReportComponent from "../components/IndigenousBenefitsReportComponent.vue";
import IndigenousProcurementProjectComponent from "../components/IndigenousProcurementProjectComponent.vue";
import IndigenousProcurementSummaryComponent from "../components/IndigenousProcurementSummaryComponent.vue";
import IndigenousProcurementCommunityComponent from "../components/IndigenousProcurementCommunityComponent.vue";
import CCABCommunityRelationsComponent from "../components/CCABCommunityRelationsComponent.vue";
import CCABBusinessDevelopmentComponent from "../components/CCABBusinessDevelopmentComponent.vue";
import CCABDiversityComponent from "../components/CCABDiversityComponent.vue";
import SummaryTargetsComponent from "../components/SummaryTargetsComponent.vue";
import IndigenousProcurementVendorComponent from "../components/IndigenousProcurementVendorComponent.vue";
import EIRDistributionComponent from "../components/EIRDistributionComponent.vue";
import ExpenseLocationSummaryReportComponent from "../components/ExpenseLocationSummaryReportComponent.vue";
import ExpenseLocationProjectReportComponent from "../components/ExpenseLocationProjectReportComponent.vue";
import ExpenseLocationOverheadReportComponent from "../components/ExpenseLocationOverheadReportComponent.vue";
import PehtaSummaryReportComponent from "../components/PehtaSummaryReportComponent.vue";
import PehtaBreakdownReportComponent from "../components/PehtaBreakdownReportComponent.vue";
import PehtaCorporateBreakdownReportComponent from "../components/PehtaCorporateBreakdownReportComponent.vue";
import PehtaCorporateSummaryReportComponent from "../components/PehtaCorporateSummaryReportComponent.vue";
import UNSDGBreakdownReportComponent from "../components/UNSDGBreakdownReportComponent.vue";
import CompactEIRDistributionComponent from "../components/CompactEIRDistributionComponent.vue";
import InclusionDevelopmentComponent from "../components/InclusionDevelopmentComponent.vue";
import { ReportData, ShortRecord, StackedBarMeta } from "../store/models";
import * as charts from "../lib/charts";
import * as colors from "../lib/colors";
import * as datetime from "../lib/datetime";
import * as _ from "lodash";
import { EmptyStackedBarMeta } from "@/store/models-empty";
import { convertToPercent } from "@/lib/currency";
import { getEnabledFeaturesForCurrentUser } from "@/lib/shared";

@Component({
  components: {
    SocialProcurementReportComponent,
    SocialProcurementComponent,
    WorkforceDiversityComponent,
    DiversityBreakdownComponent,
    IndigenousBenefitsReportComponent,
    IndigenousProcurementProjectComponent,
    IndigenousProcurementSummaryComponent,
    IndigenousProcurementCommunityComponent,
    IndigenousProcurementVendorComponent,
    CCABCommunityRelationsComponent,
    CCABBusinessDevelopmentComponent,
    CCABDiversityComponent,
    EIRDistributionComponent,
    SummaryTargetsComponent,
    SpinnerComponent,
    DialogComponent,
    ErrorComponent,
    DatePickerComponent,
    ExpenseLocationSummaryReportComponent,
    ExpenseLocationProjectReportComponent,
    ExpenseLocationOverheadReportComponent,
    PehtaSummaryReportComponent,
    PehtaBreakdownReportComponent,
    PehtaCorporateBreakdownReportComponent,
    PehtaCorporateSummaryReportComponent,
    UNSDGBreakdownReportComponent,
    CompactEIRDistributionComponent,
    InclusionDevelopmentComponent,
  },
})
export default class SocialImpactReport extends BaseReport {
  private bExportToPdfModal: boolean = false;
  private pehtaSecure = false;
  private pehtaScore = false;
  private pehtaProcurement = false;
  /*
  private totalContributionsByCommunity: any = {};
  private totalContributionsByClient: any = {};
  private totalEmployment: any[] = [];
  private totalIndigenousCommunityInvestment: any = {};
  private totalSubProjectSpend: any[] = [];
  */
  private eirSections = [
    {
      field: "gender",
      title: "Gender Identity",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "Which gender identity best describes you?",
    },
    {
      field: "orientation",
      title: "Sexual Orientation",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "Which sexual orientation best describes you?",
    },
    {
      field: "ethnicity",
      title: "Ethnicity & Heritage",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question:
        "Please select any of the communities you identify with. The intent of this question is to understand the proportion on the workforce that is Indigenous or part of a visible minority, using categories consistent with Canada’s Employment Equity Act.",
    },
    {
      field: "indigenous",
      title: "Indigenous Identity",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "Select the Indigenous identity that best describes you.",
    },
    {
      field: "disability",
      title: "Disabilities",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "If applicable, Please state any disabilities you wish to share.",
    },
    {
      field: "age",
      title: "Age",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "What year were you born?",
    },
    {
      field: "religion",
      title: "Faith & Religion",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "Please select the Faith & Religion you identify with:",
    },
    {
      field: "education",
      title: "Education",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "What is the highest degree or level of education you have completed? If currently enrolled, highest degree received.",
    },
    {
      field: "immigration",
      title: "Immigration Status",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "Have you - within the past 5 years - immigrated and been permitted to reside in Canada by immigration authorities?",
    },
    {
      field: "refugee",
      title: "Refugee Status",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question: "Have you fled your home country from persecution and been permitted to reside in Canada as a refugee by immigration authorities?",
    },
    {
      field: "veteran",
      title: "Veteran Status",
      levelCutoff: 1,
      locationCutoff: 1,
      occupationCutoff: 1,
      question:
        "Have you honourably served in the Canadian Armed Forces, the commonwealth, or its wartime allies, or as a Regular Member of the Royal Canadian Mounted Police, or as a Peace Officer in a Special Duty Area or on a Special Duty Operation, or who has served in the Merchant Navy or Ferry Command during wartime?",
    },
    {
      field: "barriers",
      title: "Barriers to Employment",
      levelCutoff: 5,
      locationCutoff: 5,
      occupationCutoff: 5,
      question: "If applicable, Please state any barrier to employment you've experienced.",
    },
  ];

  get dateRange() {
    return this.searchCriteria.dateFrom !== "" || this.searchCriteria.dateTo !== ""
      ? (this.searchCriteria.dateFrom !== "" ? datetime.formatDateForDisplay(this.searchCriteria.dateFrom) : "All") +
          " - " +
          datetime.formatDateForDisplay(this.searchCriteria.dateTo !== "" ? this.searchCriteria.dateTo : new Date().toLocaleDateString())
      : "All";
  }

  get selectedCommunity(): string {
    const selectedCommunity: ShortRecord = this.reportHelper.searchCriteria.organizations as ShortRecord;
    if (selectedCommunity !== undefined) {
      if (selectedCommunity.displayName) {
        return selectedCommunity.displayName;
      }
    }
    return "All";
  }

  get selectedProject(): string {
    const selectedProject: ShortRecord = this.reportHelper.searchCriteria.projects as ShortRecord;
    if (selectedProject !== undefined) {
      if (selectedProject.displayName) {
        return selectedProject.displayName;
      }
    }
    return "All";
  }

  protected getReportData(): ReportData | null {
    return {
      reportTitle: this.reportHelper.getReportFilenameTitle(this.reportHelper.reportType),
      reportType: this.reportType,
      companyName: this.getCurrentOrgName(this.currentOrg),
      reportLogoUrl: this.reportHelper.getLogoUrl(),
      dateRange: this.dateRange,
      preparedBy: this.currentUser.userinfo.displayName ? this.currentUser.userinfo.displayName : "",
      preparedOn: datetime.formatDateForDisplay(new Date().toDateString()),
      searchCriteria: this.searchCriteria,
      organizationName: this.getCurrentOrgName(this.currentOrg),
      title: this.reportHelper.getReportTitle(this.reportHelper.reportType),
      selectedCommunity: this.selectedCommunity,
      selectedProject: this.selectedProject,
      orgAggregate: this.currentOrgHelper.totalsAggregate,
      impactTargets: this.currentOrgHelper.impactTargets,
      beneficiaryAggregate: this.reportHelper.beneficiaryAggregateResponse,
      clientAggregate: this.reportHelper.clientsResponse,
      yearlyAggregate: this.currentOrgHelper.totalsAggregateManyYears,
      expenseDiversityReportDataTables: this.reportHelper.expenseDiversityReportDataTables,
      projectSummaryReportDataTable: this.reportHelper.projectSummaryReportDataTable,
      payeeCommunityReportDataTables: this.reportHelper.payeeCommunityReportDataTables,
      payeeCommunityReportFlattenedDataTables: this.reportHelper.payeeCommunityReportFlattenedDataTables,
      indigenousTypeReportDataTables: this.reportHelper.indigenousTypeReportDataTables,
      summaryReportDataTables: this.reportHelper.summaryReportDataTables,
      communityReportDataTables: this.reportHelper.communityReportDataTables,
      icprojectsAggregate: this.reportHelper.icprojectsAggregateResponse,
      ccabYearlyDiversityData: this.reportHelper.CCABYearlyDiversityData,
      eirLevelData: this.reportHelper.EIRLevelData,
      eirLocationData: this.reportHelper.EIRLocationData,
      eirHourlyOccupationData: this.reportHelper.EIRHourlyOccupationData,
      eirTotalData: this.reportHelper.EIRTotalData,
      eirHeadcountData: this.reportHelper.EIRHeadcountData,
      enabledFeatures: getEnabledFeaturesForCurrentUser(this.currentUser),
      // Location report data
      expenseData: this.reportHelper.expenseData,
      overheadExpenseData: this.reportHelper.overheadExpenseData,
      projectRevenueExpenseData: this.reportHelper.projectRevenueExpenseData,
      projectExpenseData: this.reportHelper.projectExpenseData,
      projectServicesExpenseData: this.reportHelper.projectServicesExpenseData,
      projectGoodsExpenseData: this.reportHelper.projectGoodsExpenseData,
      projectEquipmentExpenseData: this.reportHelper.projectEquipmentExpenseData,
      projectContributionExpenseData: this.reportHelper.projectContributionExpenseData,
      overheadServicesExpenseData: this.reportHelper.overheadServicesExpenseData,
      overheadGoodsExpenseData: this.reportHelper.overheadGoodsExpenseData,
      overheadEquipmentExpenseData: this.reportHelper.overheadEquipmentExpenseData,
      overheadContributionsExpenseData: this.reportHelper.overheadContributionsExpenseData,
      inclusionDevelopmentAgreementData: this.reportHelper.inclusionDevelopmentAgreementData,
      projectDetails: this.reportHelper.projectDetails,
      payeeDetails: this.reportHelper.payeeDetails,
      pehtaData: this.reportHelper.pehtaData,
      pehtaSecure: this.pehtaSecure,
      pehtaScore: this.pehtaScore,
      pehtaProcurement: this.pehtaProcurement,
      indigenousCommunities: this.reportHelper.indigenousCommunities,
      unsdgs: this.reportHelper.unsdgs,
      /*
      subContractingAnalysis: {
        stacks: this.totalSubProjectSpend,
        totalAmount: this.currentOrgHelper.totalsAggregate.totalProjectSpend.value,
      },
      employmentBalance: {
        stacks: this.totalEmployment,
        totalAmount: this.currentOrgHelper.totalsAggregate.totalEmployment.value,
      },
      employmentByCommunity: this.reportHelper.employmentByCommunityAggregateResponse.searchResults.results,
      employmentByOccupation: this.currentOrgHelper.employmentByOccupation,
      totalIndigenousCommunityInvestment: this.totalIndigenousCommunityInvestment,
      communityInvestmentReportItems: this.reportHelper.communityInvestmentReportItems,
      */
    };
  }
}
